var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1f5e1b95f24d7fe368755b9f48ad2096f2955438"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// // // This file configures the initialization of Sentry on the browser.
// // // The config you add here will be used whenever a page is visited.
// // // https://docs.sentry.io/platforms/javascript/guides/nextjs/

// // import * as Sentry from '@sentry/nextjs'

// Sentry.init({
//   dsn: 'https://483b445d1e6f9feddbe8f03dedb8281b@o396771.ingest.us.sentry.io/6549942',
//   // Adjust this value in production, or use tracesSampler for greater control
//   tracesSampleRate: 0.0,

//   // ...
//   // Note: if you want to override the automatic release value, do not set a
//   // `release` value here - use the environment variable `SENTRY_RELEASE`, so
//   // that it will also get attached to your source maps
// })
